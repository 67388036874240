import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import LogoIcon from '../atoms/LogoIcon';
import StyledChip from '../atoms/StyledChip';

const StyledDestinationChip = styled(StyledChip)({
    paddingLeft: 10,
    marginTop: 8,
});

const DestinationChip = ({ slug, destinationConfig, deleteDestination, isSaved }) => {
    const [url, setUrl] = useState(destinationConfig.baseUrl({ slug, destinationConfig }));

    useEffect(
        () => setUrl(destinationConfig.baseUrl({ slug, destinationConfig })),
        [slug, destinationConfig],
    );

    return (
        <Tooltip title={url}>
            {destinationConfig.default ? (
                <StyledDestinationChip
                    label={destinationConfig.name}
                    value={destinationConfig}
                    clickable={isSaved}
                    onClick={() => isSaved && window.open(url, '_blank')}
                    icon={<LogoIcon logo={destinationConfig.logo} />}
                />
            ) : (
                <StyledDestinationChip
                    label={destinationConfig.name}
                    value={destinationConfig}
                    clickable={isSaved}
                    onClick={() => isSaved && window.open(url, '_blank')}
                    icon={<LogoIcon logo={destinationConfig.logo} />}
                    onDelete={e => {
                        e.stopPropagation();
                        deleteDestination(destinationConfig);
                    }}
                    deleteIcon={<CancelIcon />}
                />
            )}
        </Tooltip>
    );
};

export default DestinationChip;
