import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Checkbox } from '@mui/material';
import { ListItemText } from '@mui/material';
import { platformList } from '../../constants/platformList';

const PlatformMultiSelect = ({ platforms, ...props }) => {
    const { item, applyValue } = props;
    const findPlatformByValue = value => {
        return Object.values(platformList).find(platform => platform.value === value);
    };

    const handleChange = (_event, newValue) => {
        applyValue({ ...item, value: newValue.map(platform => platform.value) });
    };

    return (
        <Autocomplete
            multiple
            options={platforms.map(value => findPlatformByValue(value))}
            value={
                item?.value
                    ?.map(value => findPlatformByValue(value))
                    ?.filter(platform => platforms.includes(platform.value)) ?? []
            }
            onChange={handleChange}
            disableCloseOnSelect
            getOptionLabel={option => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option.name} />
                </li>
            )}
            sx={{
                alignSelf: 'left',
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    sx={{
                        marginTop: '1rem',
                    }}
                    variant='standard'
                    placeholder='Search'
                />
            )}
        />
    );
};

export default PlatformMultiSelect;
