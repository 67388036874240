import { MenuItem, Select } from '@mui/material';
import { GOODWAY_WHITE, GOODWAY_YELLOW } from '../../constants/colors';
import withBulkEdit from '../../helpers/withBulkEdit';

const BulkEditBoolean = ({ rowObjectName, selectedRows, value, setValue, category }) => {
    const toggleMessage = `${selectedRows.length} ${rowObjectName}${
        category ? ` as ${category}` : ''
    }`;

    return (
        <Select
            value={value === null ? null : !value}
            onChange={event => setValue(!event.target.value)}
            sx={{
                width: '100%',
                '&.MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: GOODWAY_YELLOW,
                    },
                    '&:hover fieldset': {
                        borderColor: GOODWAY_YELLOW,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: GOODWAY_YELLOW,
                    },
                },
                color: GOODWAY_WHITE,
                '&:hover': {
                    color: GOODWAY_YELLOW,
                },
                '& path': {
                    color: GOODWAY_YELLOW,
                },
            }}
            displayEmpty={true}
        >
            <MenuItem value={null}>
                <em>{`Omit or Include ${toggleMessage}`}</em>
            </MenuItem>
            <MenuItem value={false}>{`Include ${toggleMessage}`}</MenuItem>
            <MenuItem value={true}>{`Omit ${toggleMessage}`}</MenuItem>
        </Select>
    );
};

export default withBulkEdit(BulkEditBoolean);
