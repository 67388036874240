import { gql } from '@apollo/client';

export const GET_DESTINATIONS_BY_DATASET_ID = gql`
  query Destinations($id: ID!) {
    getDestinations(id: $id) {
      id
      destinationMap
      destination
      result
      resultType
      datasetVersions
      artifact
      createdAt
      updatedAt
      slug
      displayName
      description
      status
      externalUrl
    }
  }
`;
