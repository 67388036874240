import styled from 'styled-components';

const Footer = styled.div`
  color: #fff;
  font-size: 35px;
  text-align: center;
  padding: 50px 0 100px 0;
  font-weight: 100;
`;

export default Footer;
