import { Box, Modal } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import useCachedState from '../../../helpers/hooks/useCachedState';
import useStaticSort from '../../../helpers/hooks/useStaticSort';
import { ModalContainer } from '../../atoms/ModalContainer';
import StyledDataGrid from '../../atoms/StyledDataGrid';
import { BulkEditColumnHeader } from '../../molecules/BulkEditColumnHeader';
import { BulkEditModalHeader } from '../../molecules/BulkEditModalHeader';

const BulkEditTable = ({
    data,
    columns,
    toolbar,
    initialState,
    initialSortModel,
    tableProps,
    rowObjectName,
    assets = [],
    getRowSubheader,
    cachePath = [],
    setDatasetRules,
}) => {
    const [selectedPage, setSelectedPage] = useState(0);
    const [page, table] = cachePath;

    const { rows, sortModel, setSortModel } = useStaticSort(
        data,
        initialSortModel,
        cachePath,
        columns,
    );

    const [selectedPageSize, setSelectedPageSize] = useCachedState(page, [table, 'pageSize'], 20);
    const [selectedRows, setSelectedRows] = useState([]);
    const [bulkEditColumn, setBulkEditColumn] = useState();
    const [editObjectName, setEditObjectName] = useState(`${rowObjectName}` || 'Rows');
    const [columnVisibility, setColumnVisibility] = useCachedState(
        page,
        [table, 'columns'],
        initialState?.columns?.columnVisibilityModel || {},
    );

    const bulkEditColumns = useMemo(
        () =>
            columns.map(column => {
                const newCol = { ...column };

                if (newCol.EditCell) {
                    newCol.renderHeader = params => (
                        <BulkEditColumnHeader
                            headerName={params.colDef.headerName}
                            selectedRows={selectedRows}
                            setBulkEditColumn={setBulkEditColumn}
                            column={newCol}
                        />
                    );
                }

                return newCol;
            }),
        [columns, selectedRows],
    );

    useEffect(() => setEditObjectName(`${rowObjectName}` || 'Rows'), [rowObjectName]);

    const endEditMode = () => setBulkEditColumn(false);

    const handleFilterForSelection = ({ filter, selection }) => {
        if (selection?.length && !isEmpty(filter?.filteredRowsLookup)) {
            const unfilteredRows = selection.filter(id => filter.filteredRowsLookup[id]);

            if (unfilteredRows.length !== selection.length) {
                setSelectedRows(unfilteredRows);
            }
        }
    };

    const components = useMemo(() => !!toolbar && { Toolbar: toolbar }, [toolbar]);

    return (
        <>
            <Box style={{ width: '100%' }}>
                <StyledDataGrid
                    onStateChange={handleFilterForSelection}
                    autoHeight
                    columns={bulkEditColumns}
                    components={components}
                    componentsProps={{ toolbar: { quickFilterProps: { debounceMs: 500 } } }}
                    disableColumnMenu
                    initialState={initialState}
                    icon={{ inactiveSortIcon: { opacity: 1 } }}
                    localeText={{
                        toolbarFilters: '',
                        toolbarColumns: 'Add Fields',
                        toolbarExportCSV: 'Export to CSV',
                    }}
                    onPageChange={setSelectedPage}
                    onPageSizeChange={setSelectedPageSize}
                    onSortModelChange={setSortModel}
                    onSelectionModelChange={setSelectedRows}
                    selectionModel={selectedRows}
                    page={selectedPage}
                    pageSize={selectedPageSize}
                    pagination
                    rows={rows || []}
                    rowsPerPageOptions={[5, 10, 20]}
                    sortModel={sortModel}
                    sortingMode='server'
                    style={{ marginBottom: 32 }}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    columnVisibilityModel={columnVisibility}
                    onColumnVisibilityModelChange={newColumnVisibility =>
                        setColumnVisibility(oldColumnVisibility => {
                            return { ...oldColumnVisibility, ...newColumnVisibility };
                        })
                    }
                    {...tableProps}
                />
            </Box>
            <Modal open={!!bulkEditColumn} onClose={endEditMode}>
                <ModalContainer style={{ minWidth: '30vw' }}>
                    <BulkEditModalHeader
                        selectedRows={selectedRows}
                        editObjectName={editObjectName}
                        rows={rows}
                        getRowSubheader={getRowSubheader}
                    />
                    {bulkEditColumn?.EditCell ? (
                        <bulkEditColumn.EditCell
                            rowObjectName={rowObjectName}
                            column={bulkEditColumn}
                            rows={rows}
                            selectedRowIds={selectedRows}
                            endEditMode={endEditMode}
                            showEditActions={bulkEditColumn.showEditActions}
                            assets={assets}
                            setDatasetRules={setDatasetRules}
                        />
                    ) : null}
                </ModalContainer>
            </Modal>
        </>
    );
};

export default BulkEditTable;
