import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GOODWAY_RED, GOODWAY_YELLOW } from '../../constants/colors';
import StyledButton from '../atoms/StyledButton';
import Confirm from './Confirm';

export const ConfirmDatasetSave = ({
    isEditMode,
    isCloning,
    handleSave,
    confirmSaveDataset,
    setConfirmSaveDataset,
    cloneName,
    setCloneName,
    datasetMeta,
}) => {
    if (!isEditMode || datasetMeta?.version?.version === datasetMeta.version?.latest) {
        const [title, setTitle] = useState('Create dataset?');
        const [message, setMessage] = useState(
            'This will create a dataset record and export data to your destinations.',
        );
        const [confirmText, setConfirmText] = useState('Create My Dataset');
        const inputPrompt = 'Please name the clone:';

        useEffect(() => {
            if (isCloning) {
                setTitle('Clone dataset?');
                const cloneFeatures = [
                    'Unsaved changes will be included in the clone.',
                    'Unsaved changes will not be applied to the current dataset.',
                    'A new Redshift destination will automatically be created.',
                    'Other destinations must be added manually.',
                    'You will be redirected to the clone.',
                ];
                setMessage(
                    <Box sx={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Typography variant='body2' color={GOODWAY_YELLOW}>
                                Cloning will have these effects:
                            </Typography>
                            <Stack spacing={1} sx={{ pl: 2 }}>
                                {cloneFeatures.map(feat => (
                                    <Typography key={feat} variant='body2'>
                                        • {feat}
                                    </Typography>
                                ))}
                            </Stack>
                        </Stack>
                    </Box>,
                );
                setConfirmText('Create Clone');
            } else if (isEditMode) {
                setTitle('Save dataset?');
                setMessage('This will overwrite your dataset and destinations.');
                setConfirmText('Save Dataset Changes');
            } else {
                setTitle('Create dataset?');
                setMessage(
                    'This will create a dataset record and export data to your destinations.',
                );
                setConfirmText('Create My Dataset');
            }
        }, [isEditMode, isCloning]);

        return (
            <Confirm
                title={title}
                message={message}
                onConfirm={() => handleSave()}
                open={confirmSaveDataset}
                setOpen={setConfirmSaveDataset}
                isInputConfirm={isCloning}
                inputPrompt={inputPrompt}
                setInputUpstream={inputText => setCloneName(inputText)}
                inputValue={cloneName}
                confirmText={confirmText}
                color='success'
                sx={{ color: 'green' }}
            />
        );
    }

    if (datasetMeta?.version?.version && datasetMeta?.version?.changes) {
        const incompatableDestinations = (datasetMeta?.destinations || []).filter(
            destination => !destination.datasetVersions.includes(datasetMeta.version?.latest),
        );

        return (
            <Confirm
                title={'Save dataset?'}
                onConfirm={() => handleSave({ updateVersion: true })}
                open={confirmSaveDataset}
                setOpen={setConfirmSaveDataset}
                confirmText={`Update Version and Save`}
                message={
                    <Box sx={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Typography
                                variant='subtitle1'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: GOODWAY_YELLOW,
                                }}
                            >
                                <NewReleasesIcon sx={{ mr: 1 }} /> There is a new dataset version
                                available.
                            </Typography>
                            <Typography variant='body2'>
                                Updating to the new version may break external processes, but will
                                make the following updates:
                            </Typography>
                            <Divider color={GOODWAY_YELLOW} />
                            <Stack spacing={1}>
                                {datasetMeta.version.changes.map(change => (
                                    <Chip
                                        key={`change-${change}`}
                                        label={change}
                                        variant='outlined'
                                        style={{
                                            color: GOODWAY_YELLOW,
                                            borderColor: GOODWAY_YELLOW,
                                        }}
                                        icon={<NewReleasesIcon color={GOODWAY_YELLOW} />}
                                    />
                                ))}
                            </Stack>
                            {!!incompatableDestinations.length && (
                                <>
                                    <Typography
                                        variant='subtitle1'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: GOODWAY_RED,
                                        }}
                                    >
                                        <NewReleasesIcon sx={{ mr: 1 }} /> The following
                                        Destinations are not compatable and will quit working if you
                                        update:
                                    </Typography>
                                    <Divider color={GOODWAY_RED} />
                                </>
                            )}
                            <Stack spacing={1}>
                                {incompatableDestinations.map(destinationName => (
                                    <Chip
                                        key={`incompatible-destination-${destinationName}`}
                                        label={destinationName}
                                        variant='outlined'
                                        style={{ color: GOODWAY_RED, borderColor: GOODWAY_RED }}
                                        icon={<PriorityHighIcon color={GOODWAY_RED} />}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </Box>
                }
                actions={
                    <StyledButton
                        variant='outlined'
                        onClick={handleSave}
                        marginleft='8px'
                        autoFocus
                    >
                        Save without updating
                    </StyledButton>
                }
            />
        );
    }
};
