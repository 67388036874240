import { Grid, Typography } from '@mui/material';

export const EmptyPricingRules = ({ setGrain, config, setSelectedRow, setOpen }) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                borderRadius: '10px',
                border: 'solid',
                borderColor: 'gray',
                borderWidth: 'thin',
                mb: 3,
            }}
            onClick={() => {
                setGrain(config.grain);
                setSelectedRow({});
                setOpen(true);
            }}
        >
            <Typography
                sx={{
                    pl: 2,
                    height: '100%',
                    color: 'white',
                    fontWeight: 100,
                    fontSpacing: '0.05em',
                    opacity: 0.5,
                    pb: '1rem',
                    pt: '1rem',
                }}
            >
                No {config.grain} adjustments.
            </Typography>
        </Grid>
    );
};
