import { Box, Chip, ClickAwayListener, Grid, Stack, Typography, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { GOODWAY_LIGHT } from '../../constants/colors';
import { platformList } from '../../constants/platformList';
import { ColumnHeader } from '../atoms/ColumnHeader';
import { PlatformLogo } from '../atoms/PlatformLogo';
import { AccountSelector } from './AccountSelector';

const AccountChip = styled(Chip)`
  color: #1a1d30;
  background-color: #fff;
  margin-right: 10px;
  margin-left: 0px;
  &&:hover {
    background-color: #fcd119;
  }
  & path {
    color: #1a1d30 !important;
  }
`;

const DatasetAccounts = ({ accountsLocked, accountMap, setAccountMap }) => {
    const [open, setOpen] = useState(false);
    // const [filteredAccounts, setFilteredAccounts] = useState(accountMap);
    const accountSearchInputRef = useRef();

    const onDelete = grainID => {
        if (!accountsLocked) {
            setAccountMap(oldSelected =>
                oldSelected.filter(({ platform_grain_id }) => platform_grain_id !== grainID),
            );
        }
    };

    return (
        <Grid item xs={12}>
            <ColumnHeader>My Accounts</ColumnHeader>
            {/*<FilterComponent setFilteredOptions={setFilteredAccounts} allOptions={accountMap} />*/}
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Stack spacing={2} style={{ marginTop: 12, width: '100%', marginBottom: 12 }}>
                    <Box
                        sx={{
                            background: GOODWAY_LIGHT,
                            padding: '15px',
                            minHeight: 40,
                            borderRadius: '10px',
                            marginBottom: '5px',
                            cursor: accountsLocked ? 'default' : 'pointer',
                        }}
                    >
                        {!!accountMap.length ? (
                            accountMap.map(account => {
                                const platform = platformList[account.platform];

                                return (
                                    <Box component={'span'} key={account.platform_grain_id}>
                                        <AccountChip
                                            sx={{ margin: 0.5 }}
                                            key={account.platform_grain_id}
                                            label={account.name}
                                            onDelete={
                                                accountsLocked
                                                    ? null
                                                    : () => onDelete(account.platform_grain_id)
                                            }
                                            disabled={accountsLocked}
                                            icon={
                                                <Box>
                                                    <PlatformLogo
                                                        platform={platform}
                                                        placement={'top'}
                                                    />
                                                </Box>
                                            }
                                        />
                                    </Box>
                                );
                            })
                        ) : (
                            <Box
                                style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
                                onClick={() => {
                                    setOpen(o => !o);
                                    accountSearchInputRef.current.focus();
                                }}
                            >
                                <Typography
                                    sx={{
                                        paddingLeft: 1,
                                        height: '100%',
                                        color: 'white',
                                        fontWeight: 100,
                                        fontSpacing: '0.05em',
                                        opacity: 0.5,
                                    }}
                                >
                                    No accounts
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {!accountsLocked && (
                        <AccountSelector
                            open={open}
                            setOpen={setOpen}
                            accountsLocked={accountsLocked}
                            accountMap={accountMap}
                            setAccountMap={setAccountMap}
                            accountSearchInputRef={accountSearchInputRef}
                        />
                    )}
                </Stack>
            </ClickAwayListener>
        </Grid>
    );
};

export default DatasetAccounts;
