import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';
import withBulkEdit from '../../helpers/withBulkEdit';
import CellTextField from '../atoms/CellTextField';

const BulkEditTextField = ({ label, column, value, setValue }) => (
    <CellTextField
        value={value}
        onChange={event => setValue(event.target.value)}
        label={label || column.headerName}
        style={{ label: { color: GOODWAY_YELLOW } }}
        inputLabelProps={{
            shrink: true,
            style: { fontSize: '20px', paddingRight: 6, backgroundColor: GOODWAY_LIGHT },
        }}
    />
);

export default withBulkEdit(BulkEditTextField);
