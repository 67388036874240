import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import FullStory, { FullStoryAPI } from 'react-fullstory';

const IdentifyFullStoryUser = (currentUser, secret) => {
    // First, generate UID, one that is not idetifieable to the user and
    // gauranteed to be unique. Once `identify` is set, it cannot be changed.
    // Without secret key, do not track. This will cause tracking mismatches.
    if (!secret) {
        console.warn('FullStory not properly configured.'); // eslint-disable-line no-console
        return true;
    }

    if (!currentUser) {
        console.warn('No Authorized User found.'); // eslint-disable-line no-console
        return true;
    }
    // Generate UID
    const hash = CryptoJS.HmacSHA256([currentUser.id, currentUser.email].join(':'), secret);
    const uid = CryptoJS.enc.Base64.stringify(hash);

    // https://help.fullstory.com/develop-js/setuservars
    FullStoryAPI('identify', uid, {
        // FullStory System Fields
        email: currentUser.email,
        displayName: currentUser.fullName,
        // FullStory Custom Fields
        userId_int: currentUser.id,
        isOkta_bool: currentUser.isOkta,
    });

    return true;
};

const LoadFullStory = ({ secret = null, orgId = null, user }) => {
    if (!orgId) {
        return null;
    }

    return <FullStory org={orgId}>{IdentifyFullStoryUser(user, secret)}</FullStory>;
};

LoadFullStory.propTypes = {
    secret: PropTypes.string,
    orgId: PropTypes.string,
};

export default LoadFullStory;
