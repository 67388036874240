import { Box } from '@mui/material';
import PageLink from '../PageLink';
import StyledButton from '../atoms/StyledButton';

const LinkedButton = ({ children, ...props }) => {
    return (
        <Box>
            <PageLink to={props.link} style={{ textDecoration: 'none' }}>
                <StyledButton type='submit' variant='outlined' highlightcolor='#f2f2f2' bold='true'>
                    {children}
                </StyledButton>
            </PageLink>
        </Box>
    );
};

export default LinkedButton;
