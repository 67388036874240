import { TextField } from '@mui/material';
import styled from 'styled-components';

const StyledPricingTextField = styled(TextField)({
    '& label': {
        color: '#fcd119',
        fontSize: '20px',
        fontWeight: 200,
    },
    '&& label.Mui-focused': {
        color: '#fcd119',
    },
    '& .MuiOutlinedInput-root': {
        color: 'white',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        '& fieldset': {
            borderRadius: 15,
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: '#fcd119',
        },
    },
    '& legend': {
        padding: '0 1em',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
    },
});

export default StyledPricingTextField;
