import StyledButton from './StyledButton';

export const ApplyButton = ({ onApply }) => (
    <StyledButton
        onClick={onApply}
        type='submit'
        flexSelf='inital'
        variant='contained'
        color='success'
        bold='true'
    >
        Apply
    </StyledButton>
);
