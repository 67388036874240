import { Autocomplete } from '@mui/material';
import styled from 'styled-components';

const StyledAutocomplete = styled(Autocomplete)({
    '& input': {
        color: 'white',
    },
    '& path': {
        color: '#fcd119',
    },
    '& fieldset': {
        borderColor: 'white',
        borderRadius: '15px',
    },
    '&&:hover .MuiInputBase-root, &&:hover .MuiOutlinedInput-notchedOutline, &&.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
            borderColor: '#fcd119',
            color: '#fcd119',
        },
});
export default StyledAutocomplete;
