import { gql } from '@apollo/client';

export const GET_DESTINATION_TYPES = gql`
  query destinationMaps($version: Int) {
    destinationMaps(version: $version) {
      id
      destination
      versionMin
      versionMax
      createdAt
      updatedAt
      artifact
      slug
      displayName
      description
      externalTemplate {
        updatedAt
      }
    }
  }
`;
