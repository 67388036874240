import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import WarnUserContext from './WarnUserContext';

const useWarnUser = currentState => {
    const [savedState, setSavedState] = useState(currentState);
    const { warnUser, setWarnUser, message } = useContext(WarnUserContext);
    const [shouldReset, setShouldReset] = useState(false);

    useEffect(() => {
        const hasChange = !isEqual(savedState, currentState);
        setWarnUser(hasChange);
    }, [currentState, savedState]);

    useEffect(() => {
        const handleBeforeUnload = warnUser
            ? event => {
                  event.returnValue = message;
                  return message;
              }
            : null;

        if (warnUser) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            if (handleBeforeUnload) {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        };
    }, [warnUser]);

    useEffect(() => {
        if (shouldReset) {
            setSavedState(currentState);
            setWarnUser(false);
            setShouldReset(false);
        }
    }, [shouldReset]);

    const resetAll = () => {
        setShouldReset(true);
    };

    const resetSaved = saved => {
        setSavedState(oldSavedState => ({ ...oldSavedState, ...saved }));
    };

    return [resetSaved, resetAll];
};

export default useWarnUser;
