import { isEmpty } from 'lodash';
import withBulkEdit from '../../helpers/withBulkEdit';
import { FileUploadContainer } from './FileUploadContainer';

const BulkFileUploadBase = withBulkEdit(({ assets, bucket, onUpload, applyChange, setValue }) => {
    const onSelect = selection => setValue(selection);

    return (
        <FileUploadContainer
            onSelect={onSelect}
            assets={assets}
            bucket={bucket}
            onUpload={onUpload}
            onApply={applyChange}
        />
    );
});

const BulkFileUpload = props => {
    const updateRow = (row, _value, file) => {
        return {
            type: 'image',
            file,
            config: {
                grain: 'creative',
                grain_id: row.grainID,
                key: row.grainID,
            },
            deleteRule: isEmpty(file),
        };
    };

    return <BulkFileUploadBase {...props} updateRow={updateRow} />;
};

export default BulkFileUpload;
