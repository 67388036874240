import { useLazyQuery } from '@apollo/client';
import { FormControl, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { GOODWAY_DARK } from '../../constants/colors';
import { GET_DESTINATION_TYPES } from '../../queries/destinationVersionMaps';
import StyledAutocomplete from '../atoms/StyledAutocomplete';
import { StyledAutocompleteInput } from '../atoms/StyledAutocompleteInput';
import StyledModal from '../atoms/StyledModal';
import DestinationChip from '../components/DestinationChip';
import SigmaDestinationChip from '../components/SigmaDestinationChip';
import { destinationList } from '../constants/destinations';

const Destinations = ({ slug, destinations, setDestinations, version, handleSave, isLoading }) => {
    const [
        getDestinationMaps,
        {
            data: { destinationMaps = [] } = {},
        },
    ] = useLazyQuery(GET_DESTINATION_TYPES, { variables: { version } });

    const [searchParams] = useSearchParams();
    const forceSigmaUpdate = searchParams.get('forceSigmaUpdate') === 'true';

    const closeModal = () => {
        setDestinationDetails();
        if (
            destinations.some(
                destination => destination.destination === 'sigma' && !destination.artifact,
            )
        ) {
            const filteredDestinations = destinations.filter(
                destination =>
                    destination.destination !== 'sigma' ||
                    (destination.destination === 'sigma' && destination.artifact),
            );
            setDestinations(filteredDestinations);
        }
    };

    const getDestinationTypes = () => {
        // After fetching the destinations that are valid for this version, count the number of destinations by type
        // e.g. redshift has 1 type, but sigma has 1 type for each template, so you are allowed up to that number
        // of Sigma destinations.
        const numAllowedByType = destinationMaps.reduce((allowedCounts, { destination }) => {
            allowedCounts[destination] = (allowedCounts[destination] || 0) + 1;

            return allowedCounts;
        }, {});

        // Check how many destinations of a given type you have already assigned.  If the number assigned == the number
        // available, that destination should no longer be an option.
        const numAssignedByType = destinations.reduce((allowedCounts, { destination }) => {
            allowedCounts[destination] = (allowedCounts[destination] || 0) + 1;

            return allowedCounts;
        }, {});

        // Finally, remove destinations that are default (and therefore should always be selected), deprecated (and
        // therefore never available), or where you've assigned the max number.
        return Object.entries(destinationList).reduce(
            (typeList, [destination, destinationType]) => {
                const numAssigned = numAssignedByType[destination];
                const numAllowed = numAllowedByType[destination];

                if (
                    !destinationType.deprecated &&
                    !destinationType.default &&
                    (!numAssigned || numAssigned < numAllowed)
                ) {
                    typeList.push({
                        ...destinationType,
                        destination,
                    });
                }

                return typeList;
            },
            [],
        );
    };

    const [destinationTypes, setDestinationTypes] = useState(getDestinationTypes());
    const [destinationDetails, setDestinationDetails] = useState();

    useEffect(() => {
        getDestinationMaps({ variables: { version } });
    }, [slug, version]);

    useEffect(() => setDestinationTypes(getDestinationTypes()), [destinations, destinationMaps]);

    const handleOnChange = (_event, selectedDestinations) => {
        const newDestinations = selectedDestinations.map(selected => ({
            ...selected,
            id: selected.id || uuidv4(),
        }));
        const destinationIds = destinations.map(({ id }) => id);
        const addedDestination = newDestinations.find(({ id }) => !destinationIds.includes(id));

        setDestinations(newDestinations);

        if (addedDestination.details) {
            setDestinationDetails(() => (
                <addedDestination.details
                    destinations={newDestinations}
                    setDestinations={setDestinations}
                    destination={addedDestination}
                    destinationMaps={destinationMaps}
                    close={closeModal}
                />
            ));
        }
    };

    const deleteDestination = deletedDestination =>
        setDestinations(oldDestinations =>
            oldDestinations.filter(({ id }) => id !== deletedDestination.id),
        );

    return (
        <Grid item xs={12}>
            <Grid container direction='column' sx={{ marginBottom: 5 }}>
                <FormControl>
                    <StyledAutocomplete
                        sx={{
                            '.MuiFormLabel-root': {
                                paddingRight: 1,
                                backgroundColor: GOODWAY_DARK,
                            },
                            '.MuiAutocomplete-inputRoot': {
                                paddingTop: '15px !important',
                                paddingBottom: '15px !important',
                            },
                            '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                display: 'none',
                            },
                        }}
                        multiple
                        isOptionEqualToValue={() => false}
                        disableClearable
                        value={destinations}
                        onChange={handleOnChange}
                        options={destinationTypes}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                            <StyledAutocompleteInput
                                label={'Destinations'}
                                {...params}
                                variant='outlined'
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                            />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                const workbookCreatedAt = new Date(option?.createdAt);
                                const templateUpdatedAt = new Date(
                                    option?.externalTemplate?.updatedAt,
                                );
                                const canUpdate =
                                    forceSigmaUpdate || workbookCreatedAt < templateUpdatedAt;
                                return option.destination === 'sigma' ? (
                                    <SigmaDestinationChip
                                        {...getTagProps({ index })}
                                        slug={slug}
                                        destinationConfig={option}
                                        deleteDestination={deleteDestination}
                                        isSaved={option.isSaved}
                                        canUpdate={canUpdate}
                                        handleSave={handleSave}
                                        key={`destination-${option.destination}-${option.id}`}
                                        isLoading={isLoading}
                                    />
                                ) : (
                                    <DestinationChip
                                        {...getTagProps({ index })}
                                        slug={slug}
                                        destinationConfig={option}
                                        deleteDestination={deleteDestination}
                                        isSaved={option.isSaved}
                                        key={`destination-${option.destination}-${option.id}`}
                                    />
                                );
                            })
                        }
                    />
                </FormControl>
            </Grid>
            <StyledModal onClose={closeModal} open={!!destinationDetails}>
                {destinationDetails}
            </StyledModal>
        </Grid>
    );
};

export default Destinations;
