import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import ChannelSelect from './ChannelSelect';
import { InheritedValueTooltip } from './InheritedValueTooltip';

const AutocompleteChannelCell = ({
    row,
    applyRowChange,
    rowIndex,
    isValueInherited = true,
    grainType,
}) => {
    const [selectValue, setSelectValue] = useState(row.tags?.channel?.config?.tag_value || '');
    const [inheritedValue, setInheritedValue] = useState(row.inheritedRules?.tags?.channel);
    const [inheritedAlias, setInheritedAlias] = useState(row.inheritedRules?.alias);

    useEffect(
        () => setSelectValue(row.tags?.channel?.config?.tag_value || ''),
        [row.tags?.channel],
    );
    useEffect(() => {
        setInheritedValue(row.inheritedRules?.tags?.channel);
        setInheritedAlias(row.inheritedRules?.alias);
    }, [row.inheritedRules]);

    return (
        <ChannelSelect
            selectValue={selectValue}
            initialValue={row.tags?.channel?.config?.tag_value || ''}
            placeholder={inheritedValue?.config?.tag_value || ''}
            onChange={(_event, newValue) => {
                const grain = `${grainType}_id`;
                const tag = {
                    type: 'tag',
                    grain: {
                        platform_grain_id: row.grainID,
                        name: row.alias?.config?.value || row.name,
                        platform: row.platform,
                        type: grainType,
                    },
                    config: {
                        tag_type: 'alias',
                        field: grain,
                        key: row.grainID,
                        bucket: 'channel',
                        grain,
                        grain_id: row.grainID,
                        tag_value: newValue,
                        reference: false,
                    },
                };

                setSelectValue(newValue || '');

                applyRowChange(row, tag, rowIndex, !newValue);
            }}
            getInputProps={selectValue => {
                return isValueInherited && !selectValue && inheritedValue
                    ? {
                          endAdornment: (
                              <>
                                  <InputAdornment position='end'>
                                      <Box sx={{ marginTop: '.5rem', position: 'relative' }}>
                                          <InheritedValueTooltip
                                              inheritedValue={inheritedValue}
                                              inheritedAlias={inheritedAlias}
                                          />
                                      </Box>
                                  </InputAdornment>
                                  <InputAdornment position='end'>
                                      <Box
                                          sx={{ marginTop: '.5rem', marginLeft: '0 !important' }}
                                          //   onClick={() => setOpen(true)}
                                      >
                                          <ArrowDropDownIcon />
                                      </Box>
                                  </InputAdornment>
                              </>
                          ),
                      }
                    : {};
            }}
        />
    );
};

export default AutocompleteChannelCell;
