import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { hashIds } from '../../helpers/utils';
import useCachedState from './useCachedState';

const useStaticSort = (data, initialSortModel, cachePath, columns = []) => {
    const [page, ...tablePath] = cachePath;
    const [sortModel, setSortModel] = useCachedState(
        page,
        [...tablePath, 'sortModel'],
        initialSortModel,
    );
    const [rows, setRows] = useState();
    const dataRef = useRef();

    const applySort = (valueGetter, a, b, sort) => {
        const fieldA = valueGetter({ row: a });
        const fieldB = valueGetter({ row: b });
        const isNumeric = !isNaN(fieldA) && !isNaN(fieldB);

        let comparison = 0;

        if (fieldA === null || fieldA === undefined) {
            return sort === 'asc' ? 1 : -1;
        }
        if (fieldB === null || fieldB === undefined) {
            return sort === 'asc' ? -1 : 1;
        }
        if (isNumeric) {
            comparison = fieldA - fieldB;
        } else {
            comparison = fieldA.localeCompare(fieldB);
        }

        if (comparison !== 0) {
            return sort === 'asc' ? comparison : -comparison;
        }
        return 0;
    };

    const getSortedRows = () => {
        if (isEmpty(sortModel) || !data) {
            return data;
        }

        const { field, sort } = sortModel[0];
        const column = columns?.find(column => column.field === field);
        const { valueGetter = ({ row }) => row[field] } = column || {};

        return [...data].sort((a, b) => applySort(valueGetter, a, b, sort));
    };

    useEffect(() => setRows(getSortedRows), [sortModel]);

    useEffect(() => {
        const dataHash = hashIds(data);

        if (!data) {
            setRows();
            return;
        }
        if (!dataHash && !rows?.length) {
            setRows([]);
            return;
        }
        if (dataRef.current !== dataHash) {
            dataRef.current = dataHash;
            setRows(getSortedRows());
        } else {
            setRows(oldRows =>
                oldRows?.map(row => {
                    const updatedRow = data.find(d => d.id === row.id);

                    if (updatedRow) {
                        const newRow = { ...updatedRow };

                        return newRow;
                    }

                    return row;
                }),
            );
        }
    }, [data]);

    return { rows, sortModel, setSortModel };
};

export default useStaticSort;
