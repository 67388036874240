import styled from 'styled-components';

const PageTitle = styled.div`
  color: #fff;
  font-size: 35px;
  text-align: center;
  padding: 40px 0 50px 0;
  font-weight: 100;
`;

export default PageTitle;
