import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';
import { PRICING_CONFIG } from '../../helpers/pricing';
import StyledButton from '../atoms/StyledButton';
import PricingModal from '../molecules/PricingModal';
import { EmptyPricingRules } from './EmptyPricingRules';
import { PricingTable } from './PricingTable';

const PricingAccordion = ({
    dimensions,
    loading,
    pricingRules,
    setPricingRules,
    excludeFinancialColumns,
    datasetMeta,
    setDatasetMeta,
}) => {
    const [grain, setGrain] = useState('');
    const [selectedRow, setSelectedRow] = useState({});
    const [open, setOpen] = useState(false);

    const rows = useMemo(() => {
        return PRICING_CONFIG.reduce(
            (acc, config) => {
                const grain = config.grain;
                const grainRows = pricingRules?.[grain]?.reduce((activeRules, rule) => {
                    const ruleGrains =
                        rule.grain_ids ||
                        rule?.grains?.map(({ platform_grain_id }) => platform_grain_id) ||
                        [];
                    const dims = dimensions?.[grain]?.filter(dim =>
                        ruleGrains.includes(dim.platform_grain_id),
                    );

                    if (!isEmpty(dims)) {
                        rule.grains = dims;
                        activeRules.push(rule);
                    }

                    return activeRules;
                }, []);

                return { ...acc, [grain]: grainRows };
            },
            {
                account: [],
                campaign: [],
                placement: [],
            },
        );
    }, [pricingRules, dimensions]);

    return (
        <Accordion
            sx={{
                backgroundColor: GOODWAY_LIGHT,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
                marginTop: 3,
                marginBottom: 1,
                borderRadius: '10px',
                boxShadow: 'none',
            }}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon sx={{ pointerEvents: 'auto', color: GOODWAY_YELLOW }} />
                }
            >
                <Typography
                    variant={'h5'}
                    style={{ color: '#fcd119', fontSize: 19, fontWeight: 300 }}
                >
                    Pricing Adjustments
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pr: 0 }}>
                <Grid item sx={{ width: '100%', height: 12 }}>
                    {loading && <LinearProgress />}
                </Grid>
                <Grid container spacing={3} style={{ height: '50%', width: '100%', pr: 0 }}>
                    <Grid item xs={12} sx={{ mt: 0, pt: 0, mb: '1rem', pl: 0, pr: 0 }}>
                        <Typography
                            variant={'body'}
                            style={{ color: 'gray', fontSize: 17, fontWeight: 300 }}
                        >
                            NOTE: You do not need to add a pricing adjustment for dynamic TTD
                            campaigns. Fee cards from TTD will automatically be applied to your
                            dataset.
                        </Typography>
                    </Grid>
                    {PRICING_CONFIG.filter(x => !!dimensions[x.grain]?.length).map(config => (
                        <Grid
                            item
                            xs={12}
                            key={`pricing-config-grid-${config.grain}`}
                            sx={{ mt: 0, pt: 0, mb: 0, pl: 0, pr: 0 }}
                        >
                            <Typography
                                variant={'h4'}
                                sx={{
                                    color: 'white',
                                    fontSize: 17,
                                    fontWeight: 300,
                                    textTransform: 'capitalize',
                                    marginBottom: '1rem',
                                }}
                            >
                                {`${config.grain} Adjustments`}
                            </Typography>
                            <StyledButton
                                variant='outlined'
                                onClick={() => {
                                    setGrain(config.grain);
                                    setSelectedRow({});
                                    setOpen(true);
                                }}
                                highlightcolor={GOODWAY_YELLOW}
                                size={'small'}
                                sx={{ ml: 3, mb: '1rem' }}
                            >
                                {config.label}
                            </StyledButton>
                            {isEmpty(rows?.[config.grain]) ? (
                                <EmptyPricingRules
                                    setGrain={setGrain}
                                    config={config}
                                    setSelectedRow={setSelectedRow}
                                    setOpen={setOpen}
                                />
                            ) : (
                                <PricingTable
                                    rows={rows[config.grain]}
                                    config={config}
                                    setGrain={setGrain}
                                    setOpen={setOpen}
                                    setPricingRules={setPricingRules}
                                    setSelectedRow={setSelectedRow}
                                />
                            )}
                        </Grid>
                    ))}
                    <Tooltip
                        title='Note: This will zero out data from columns that contain financial information from the dataset (fee, fees, markup, margin, platform_spend)'
                        arrow
                    >
                        <FormControlLabel
                            style={{
                                color: 'white',
                                fontSize: 17,
                                fontWeight: 300,
                                margin: '8px 12px',
                            }}
                            sx={{
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: GOODWAY_YELLOW,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: GOODWAY_YELLOW,
                                    },
                                    '&.MuiFocused fieldset': {
                                        borderColor: GOODWAY_YELLOW,
                                    },
                                },
                                '&:hover': {
                                    color: GOODWAY_YELLOW,
                                },
                                '& path': {
                                    color: GOODWAY_YELLOW,
                                },
                            }}
                            control={
                                <Checkbox
                                    checked={excludeFinancialColumns}
                                    onChange={event =>
                                        setDatasetMeta({
                                            ...datasetMeta,
                                            excludeFinancialColumns: event.currentTarget.checked,
                                        })
                                    }
                                />
                            }
                            label='Exclude sensitive financial information?'
                        />
                    </Tooltip>
                    <PricingModal
                        row={selectedRow}
                        grain={grain}
                        dimensions={dimensions}
                        pricingRules={pricingRules}
                        setPricingRules={setPricingRules}
                        open={open}
                        setOpen={setOpen}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default PricingAccordion;
