import withBulkEdit from '../../helpers/withBulkEdit';
import ChannelSelect from '../atoms/ChannelSelect';

const BulkEditChannelAutocompleteCell = ({ value, setValue }) => {
    return (
        <ChannelSelect
            selectValue={value}
            onChange={(_event, newValue) => setValue(newValue)}
            label={'Channel'}
        />
    );
};

export default withBulkEdit(BulkEditChannelAutocompleteCell);
