import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { formatDimensionName } from '../../helpers/dataset';

const GrainSelector = ({ grainOptions, currentDimension, setCurrentDimension }) => {
    return (
        <FormControl size={'small'} style={{ minWidth: 120 }}>
            <InputLabel
                id={'dimension-selector-label'}
                style={{ marginLeft: -14, marginTop: 6, color: '#FCD119', fontSize: 16 }}
            >
                Dimension
            </InputLabel>
            <Select
                labelId={'dimension-selector-label'}
                sx={{
                    '&:before': {
                        border: 'white !important',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: '#FCD119 !important',
                    },
                    '&:after': {
                        borderColor: 'white !important',
                    },
                }}
                value={(!!grainOptions.length && currentDimension) || ''}
                onChange={event => setCurrentDimension(event.target.value)}
                variant={'standard'}
                style={{
                    color: 'white',
                    marginRight: 12,
                }}
            >
                {grainOptions.map(dimension => (
                    <MenuItem key={dimension} value={dimension}>
                        {`${formatDimensionName(dimension)}s`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GrainSelector;
