import { useEffect, useRef, useState } from 'react';
import InheritableTextInput from '../molecules/InheritableTextInput';

const TextInputTagCell = ({
    row,
    tagId,
    applyRowChange,
    rowIndex,
    initialValueGetter = tags => tags && tags[tagId] && tags[tagId].config?.tag_value,
    allowSpecialCharacters = false,
    grainType,
}) => {
    const [tag, setTag] = useState(initialValueGetter(row?.tags));
    const [inheritedValue, setInheritedValue] = useState(
        initialValueGetter(row.inheritedRules?.tags),
    );
    const initialValueRef = useRef(initialValueGetter(row?.tags));

    useEffect(() => {
        const initialTagValue = initialValueGetter(row.tags);
        setTag(initialTagValue);
        initialValueRef.current = initialTagValue;
    }, [row?.tags]);

    useEffect(() => {
        setInheritedValue(initialValueGetter(row.inheritedRules?.tags));
    }, [row?.inheritedRules]);

    return (
        <InheritableTextInput
            value={tag}
            placeholder={inheritedValue}
            onBlur={({ target }) => {
                const { value } = target;
                const grain = `${grainType}_id`;

                if (value !== initialValueRef.current) {
                    applyRowChange(
                        row,
                        {
                            type: 'tag',
                            grain: {
                                platform_grain_id: row.grainID,
                                name: row.alias?.config?.value || row.name,
                                platform: row.platform,
                                type: grainType,
                            },
                            config: {
                                tag_type: 'alias',
                                field: grain,
                                key: row.grainID,
                                bucket: tagId,
                                grain,
                                grain_id: row.grainID,
                                tag_value: value,
                                reference: false,
                            },
                        },
                        rowIndex,
                        !value,
                    );
                }
            }}
            onChange={({ target }) => {
                const { value } = target;
                setTag(value);
            }}
            allowSpecialCharacters={allowSpecialCharacters}
            inheritedRules={{
                inheritedAlias: row.inheritedRules?.alias,
                inheritedValue: row.inheritedRules?.tags && row.inheritedRules.tags[tagId],
            }}
        />
    );
};

export default TextInputTagCell;
