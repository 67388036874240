import { gql } from '@apollo/client';

export const GET_GRAIN_HIERARCHY = gql`
  query getGrainHierarchy($accountMap: [AccountMapInput!]!, $grainTypes: [String!]!) {
    getGrainHierarchy(accountMap: $accountMap, grainTypes: $grainTypes) {
      id
      platform_grain_id
      name
      type
      platform
      path
    }
  }
`;

export const GET_ALL_ACCOUNTS = gql`
  query Accounts {
    accounts {
      platform_grain_id
      name
      type
      platform
    }
    managerAccounts {
      id
      platform_grain_id
      name
      platform
      accountIds
      accounts {
        id
        platform
        name
        platform_grain_id
        type
      }
    }
  }
`;

export const GET_PRICING_GRAINS = gql`
  query PricingGrains($accountMap: [AccountMapInput!]!) {
    pricingGrains(accountMap: $accountMap) {
      account {
        id
        platform_grain_id
        name
        type
        platform
        path
      }
      campaign {
        id
        platform_grain_id
        name
        type
        platform
        path
      }
      placement {
        id
        platform_grain_id
        name
        type
        platform
        path
      }
    }
  }
`;
