import { startCase } from 'lodash';

export const isValidDatasetName = (newDatasetName, datasetId, allDatasets) => {
    const cleanDatasetName = cleanValue(newDatasetName);

    if (/^[^A-Za-z]/.test(cleanDatasetName.charAt(0))) {
        return {
            status: false,
            errorMessage: 'Dataset names cannot begin with a number or special character.',
        };
    }
    if (
        !allDatasets ||
        allDatasets.find(d => cleanValue(d.name) === cleanDatasetName && d.id !== datasetId)
    ) {
        return {
            status: false,
            errorMessage: `Dataset assets for '${newDatasetName}' already exist.`,
        };
    }
    return {
        status: true,
    };
};

export const cleanValue = value => value.toLowerCase().replace(/[^A-Z0-9]+/gi, '_');

export const formatDimensionName = dimension => startCase(dimension.replace('_', ' '));

export const getDimensionMeta = ({ data, dimension, datasetRules }) => {
    const { type, platform_grain_id } = dimension;
    const dimensionMeta = {};

    switch (type) {
        case 'creative': {
            const creativeSize = data.getGrainHierarchy.find(({ path }) =>
                path.includes(platform_grain_id),
            );
            const sizeAlias =
                datasetRules.find(
                    rule =>
                        rule.type === 'alias' &&
                        rule.config?.default_field === 'creative_size_alias' &&
                        rule.config.key === (creativeSize?.platform_grain_id || ''),
                ) || undefined;

            if (creativeSize) {
                dimensionMeta.creativeSize = creativeSize?.name;
                dimensionMeta.creativeSizeId = creativeSize?.platform_grain_id;
            }

            dimensionMeta.sizeAlias = sizeAlias;
        }
    }

    return dimensionMeta;
};
