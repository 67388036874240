import { getCreativeUrl } from '../../helpers/utils';
import TextInputTagCell from './TextInputTagCell';

const CreativeUrlTextCell = ({ row, tagId, applyRowChange, rowIndex, grainType }) => {
    return (
        <TextInputTagCell
            row={row}
            tagId={tagId}
            applyRowChange={applyRowChange}
            rowIndex={rowIndex}
            allowSpecialCharacters={true}
            grainType={grainType}
            valueGetter={value => ({
                key: value,
                type: 'url',
            })}
            isValueInherited={false}
            initialValueGetter={getCreativeUrl}
        />
    );
};

export default CreativeUrlTextCell;
