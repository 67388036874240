import { TextField } from '@mui/material';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  align-self: flex-end;
  /* default */
  .MuiInput-underline:before {
    border-bottom: none;
  }
  /* hover */
  && .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: none;
  }
  .MuiInput-input {
    color: white;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -ms-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
    font-weight: 100;
    font-spacing: 0.05em;
  }

  padding: 20px 0;
  margin: 25px 0;
  font-weight: 100;
`;

export default StyledTextField;
