import { gql } from '@apollo/client';

export const SUBMIT_FEEDBACK = gql`
  mutation Mutation($text: String, $rating: Int, $url: String!) {
    logFeedback(text: $text, rating: $rating, url: $url) {
      code
      success
      message
    }
  }
`;
