import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Chip, Grid, Tooltip, styled } from '@mui/material';
import GoodwayLogoBlue from '../../assets/logo2.svg';
import GoodwayLogoWhite from '../../assets/logo2_white.svg';
import { GOODWAY_DARK, GOODWAY_YELLOW } from '../../constants/colors';
import PageLink from '../PageLink';

const LogoWrapper = styled(Box)`
  padding: 20px 0 0 20px;
`;

const StyledChip = styled(Chip)`
  color: ${GOODWAY_YELLOW};
  background-color: ${GOODWAY_DARK};
  border: 1px solid ${GOODWAY_YELLOW};
  text-transform: uppercase;
  margin-right: 0px;
  margin-left: 0.3rem;
  margin-bottom: 3rem;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledHelp = styled(HelpOutlineIcon)`
  color: ${GOODWAY_YELLOW};
  background-color: ${GOODWAY_DARK};
  &:hover {
    color: white;
  }
`;

const GOODLOOK_HELP_URL =
    'https://goodwaygroup.atlassian.net/wiki/spaces/Data/pages/387547137/Goodlook';

const Header = ({ color }) => {
    const source = color === 'blue' ? GoodwayLogoBlue : GoodwayLogoWhite;

    return (
        <Grid container justifyContent='space-between' alignItems='center' style={{ zIndex: 1250 }}>
            <Grid item>
                <PageLink to='/' style={{ textDecoration: 'none' }}>
                    <LogoWrapper>
                        <img width={150} src={source} alt='Goodway Logo' />
                        <StyledChip label='BETA' size='small' outlined='true' />
                    </LogoWrapper>
                </PageLink>
            </Grid>
            <Grid item>
                <Tooltip title='Goodlook Resources' placement='bottom'>
                    <a href={GOODLOOK_HELP_URL} target='_blank' rel='noopener noreferrer'>
                        <StyledHelp
                            sx={{
                                marginRight: '4.5rem',
                                marginBottom: '1rem',
                                fontSize: '2rem',
                            }}
                        />
                    </a>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default Header;
