import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';

const colors = {
    confirmationButtonInactive: '31C563',
    confirmationButtonInactiveHover: '41D573',
    confirmationButtonUpdate: '2E7D32',
    confirmationButtonUpdateHover: '166B20',
    confirmationButtonActive: 'F59341',
    confirmationButtonActiveHover: 'E58331',
};

const styles = {
    activeButton: {
        margin: '8px 0',
        backgroundColor: `#${colors.confirmationButtonActive}`,
        '&:hover': { backgroundColor: `#${colors.confirmationButtonActiveHover}` },
    },
    updateButton: {
        margin: '8px 0',
        backgroundColor: `#${colors.confirmationButtonUpdate}`,
        '&:hover': { backgroundColor: `#${colors.confirmationButtonUpdateHover}` },
    },
    inactiveButton: {
        margin: '8px 0',
        backgroundColor: `#${colors.confirmationButtonInactive}`,
        '&:hover': { backgroundColor: `#${colors.confirmationButtonInactiveHover}` },
    },
};

const ConfirmationButton = ({
    buttonText,
    confirmText,
    isDisabled,
    onClick,
    timeout = 5000,
    update = false,
    loading = false,
    fullWidth = false,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const reset = () => {
        setIsConfirming(false);
    };

    const confirm = e => {
        e.stopPropagation();
        setIsConfirming(true);
        setTimeout(reset, timeout);
    };

    return isConfirming ? (
        <LoadingButton
            loading={loading}
            sx={styles.activeButton}
            disabled={isDisabled}
            variant='contained'
            onClick={e => {
                onClick(e);
                setIsConfirming(false);
            }}
            fullWidth={fullWidth}
        >
            {confirmText}
        </LoadingButton>
    ) : (
        <LoadingButton
            loading={loading}
            sx={{ ...(update ? styles.inactiveButton : styles.updateButton) }}
            disabled={isDisabled}
            variant='contained'
            onClick={confirm}
            fullWidth={fullWidth}
        >
            {buttonText}
        </LoadingButton>
    );
};

export default ConfirmationButton;
