import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';

const UpdatedStyledTextField = styled(TextField)({
    '& label': {
        color: '#fcd119',
        fontSize: '20px',
        fontWeight: 200,
    },
    '& label.Mui-focused': {
        color: '#fcd119',
        '&.Mui-error': {
            color: 'red',
        },
    },
    '& .MuiOutlinedInput-root': {
        color: 'white',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        '& fieldset': {
            borderRadius: 15,
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: '#fcd119',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fcd119',
        },
        '&.Mui-error fieldset': {
            borderColor: 'red !important',
            '&.Mui-focused, &:hover': {
                borderColor: 'red !important',
            },
        },
    },
    '&.Mui-error label': {
        color: 'red',
    },
    '& legend': {
        padding: '0 1em',
    },
    '&': {
        width: '100%',
    },
});

const SigmaAccordion = ({ loading, datasetName, sigmaFolderName, onSigmaFolderNameChange }) => {
    const [localSigmaFolderName, setLocalSigmaFolderName] = useState(
        sigmaFolderName || datasetName,
    );
    const [isAliasModified, setIsAliasModified] = useState(false);

    useEffect(() => {
        if (!isAliasModified) {
            setLocalSigmaFolderName(sigmaFolderName || datasetName);
        }
    }, [sigmaFolderName, datasetName, isAliasModified]);

    const handleSigmaFolderNameChange = event => {
        const newValue = event.target.value;
        setLocalSigmaFolderName(newValue);
        setIsAliasModified(true);
        onSigmaFolderNameChange(newValue);
    };

    const handleSigmaFolderNameBlur = () => {
        if (localSigmaFolderName.trim() === '') {
            setLocalSigmaFolderName(datasetName);
            setIsAliasModified(false);
        }
    };

    return (
        <Accordion
            sx={{
                backgroundColor: GOODWAY_LIGHT,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
                marginTop: 3,
                marginBottom: 1,
                borderRadius: '10px',
                boxShadow: 'none',
            }}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon sx={{ pointerEvents: 'auto', color: GOODWAY_YELLOW }} />
                }
            >
                <Typography
                    variant={'h5'}
                    style={{ color: '#fcd119', fontSize: 19, fontWeight: 300 }}
                >
                    Sigma Configuration
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pr: 0 }}>
                <Grid item sx={{ width: '100%', height: 12 }}>
                    {loading && <LinearProgress />}
                </Grid>
                <Grid container spacing={3} style={{ height: '50%', width: '100%', pr: 0 }}>
                    <Grid item xs={12} sx={{ mt: 0, pt: 0, mb: '1rem', pl: 0, pr: 0 }}>
                        <Typography
                            variant={'body'}
                            style={{
                                color: 'gray',
                                fontSize: 17,
                                fontWeight: 300,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            Sigma Folder Aliasing
                            <Tooltip
                                title='This will update the name of the folder seen by clients in Sigma.'
                                arrow
                            >
                                <IconButton size='small' sx={{ ml: 1, color: GOODWAY_YELLOW }}>
                                    <InfoIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <UpdatedStyledTextField
                            fullWidth
                            label='Sigma Folder Alias'
                            variant='outlined'
                            value={localSigmaFolderName}
                            onChange={handleSigmaFolderNameChange}
                            onBlur={handleSigmaFolderNameBlur}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                style: {
                                    height: '50px',
                                    padding: '10px 10px 10px 16px',
                                    fontWeight: 100,
                                },
                            }}
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default SigmaAccordion;
