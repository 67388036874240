import { Box, TextField, Typography } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { platformList } from '../../constants/platformList';
import { getGrainHeader } from '../../helpers/pricing';
import { PlatformLogo } from '../atoms/PlatformLogo';
import StyledAutocomplete from '../atoms/StyledAutocomplete';
import StyledChip from '../atoms/StyledChip';

const PricingAutocomplete = ({
    grain,
    options,
    selected,
    setSelected,
    scrollContainerRef,
    setDropdownOpen,
}) => {
    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['name', 'platform_grain_id', 'alias'] });

    return (
        <StyledAutocomplete
            filterOptions={filterOptions}
            id='grain-input-label'
            label={`${getGrainHeader(grain)} *`}
            multiple
            onOpen={() => {
                setDropdownOpen(true);
                const scrollContainer = scrollContainerRef.current;
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            }}
            onClose={() => setDropdownOpen(false)}
            clearOnEscape={false}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable
            disableCloseOnSelect
            clearOnBlur={false}
            value={selected}
            onChange={(_e, newValue) => setSelected(newValue)}
            onBlur={() => {
                const scrollContainer = scrollContainerRef.current;
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                }
            }}
            options={options}
            groupBy={option => platformList[option?.platform]?.name || ''}
            getOptionLabel={option => option.alias || option.name}
            renderOption={(props, option) => (
                <li {...props}>
                    <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                            <PlatformLogo
                                platform={platformList[option.platform]}
                                placement='top'
                            />
                        </Box>
                        <Typography>{option.alias?.config?.alias || option.name}</Typography>
                    </Box>
                </li>
            )}
            sx={{
                width: '100%',
                maxHeight: 300,
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant='outlined'
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true,
                        mt: '-3px', // Add negative margin top to remove the background color
                    }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <StyledChip
                        key={option.id}
                        label={
                            <Typography sx={{ whiteSpace: 'normal', pt: '5px', pb: '5px' }}>
                                {option.alias?.config?.alias || option.name}
                            </Typography>
                        }
                        sx={{ height: '100%', textTransform: 'none' }}
                        icon={
                            <Box>
                                <PlatformLogo
                                    platform={platformList[option?.platform || '']}
                                    placement={'top'}
                                />
                            </Box>
                        }
                        {...getTagProps({ index })}
                    />
                ))
            }
        />
    );
};

export default PricingAutocomplete;
