import redshiftLogo from '../../assets/destinationLogos/redshiftLogo.svg';
import sigmaLogo from '../../assets/destinationLogos/sigmaLogo.svg';
import SigmaDestinationConfig from '../components/SigmaDestinationConfig';

export const destinationList = {
    redshift: {
        name: 'Redshift',
        destination: 'redshift',
        logo: redshiftLogo,
        default: true,
        baseUrl: ({ slug }) =>
            `https://gw-data.goodwaygroup.com/queries/4272/source?p_activities_view=${slug}_activities&p_performance_view=${slug}_performance&p_reach_view=${slug}_reach`,
    },
    sigma: {
        name: 'Sigma',
        initialize: destination => {
            return {
                name: `Sigma (${destination?.displayName})`,
                externalTemplate: destination?.externalTemplate,
            };
        },
        logo: sigmaLogo,
        baseUrl: ({ destinationConfig }) => destinationConfig.externalUrl,
        details: SigmaDestinationConfig,
    },
};
