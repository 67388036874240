import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { Tooltip } from '@mui/material';
import { UI_FRIENDLY_DIMENSIONS } from '../../constants/dimensions';
import { platformList } from '../../constants/platformList';

export const InheritedValueTooltip = ({ inheritedValue, inheritedAlias }) => {
    return (
        <Tooltip
            title={
                <>
                    {inheritedValue?.grain
                        ? `${UI_FRIENDLY_DIMENSIONS[inheritedValue.grain.type]}: ${
                              inheritedAlias?.config?.alias || inheritedValue.grain.name
                          }`
                        : 'Inherited Value'}
                    <br />
                    {inheritedValue?.grain?.platform &&
                    platformList?.[inheritedValue.grain.platform]
                        ? `Platform: ${platformList?.[inheritedValue.grain.platform].name}`
                        : ''}
                </>
            }
        >
            <AccountTreeOutlinedIcon fontSize='small' />
        </Tooltip>
    );
};
