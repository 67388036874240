import { DataGridPro } from '@mui/x-data-grid-pro';
import styled from 'styled-components';
import { GOODWAY_LIGHT, GOODWAY_WHITE, GOODWAY_YELLOW } from '../../constants/colors';

const StyledDataGrid = styled(DataGridPro)`
  && {
    width: 100%;
    margin: 0 auto;
    border: none;
  }
  & .MuiDataGrid-columnHeader {
    height: 80px;
    font-size: 15px;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none !important
  }
  & .MuiDataGrid-pinnedColumnHeaders {
    background: ${GOODWAY_LIGHT};
    border-right: none;   
  }
  & .MuiDataGrid-pinnedColumns {
    background: ${GOODWAY_LIGHT};
    border-right: none;
    font-weight 200;
  }
  & .MuiDataGrid-cell:focus-within{
    outline: none !important;
  }
  & .MuiDataGrid-rowCount {
    display: none;
  }
  & .MuiDataGrid-columnHeaderTitle {
    color: ${GOODWAY_YELLOW};
    font-weight 300;
  }
  & .MuiDataGrid-iconSeparator {
    display: none;
  }
  & .MuiDataGrid-cellContent {
    color: white;
    font-weight: 100;
  }
  
  ${props =>
      props.selection
          ? `
      & .MuiDataGrid-row {
        cursor: pointer !important;
      }
      & .MuiDataGrid-cell:focus {
        outline:none !important;
        box-shadow: none !important;
      }
      & .MuiDataGrid-virtualScrollerRenderZone {
        position: initial !important;
      }
      & .MuiDataGrid-row.Mui-selected {
        max-width: 100% !important;
        border-radius: 5px;
        overflow-x: clip;
        box-sizing: border-box !important;
        border: 3px solid ${GOODWAY_YELLOW} !important;
        background-color: transparent !important;
      }`
          : ''}
  & .muidatagrid-row: {
    max-height: none !important;
  }
  & .muidatagrid-renderingzone: {
    max-height: none !important;
  }
  & .MuiToolbar-regular {
    color: white;
  }
  & path {
    color: ${GOODWAY_YELLOW};
  }
  .MuiInput-input {
    color: white;
    transition: color 0.5s;
    font-weight: 100;
    font-spacing: 0.05em;
  }
  
  .MuiDataGrid-selectedRowCount {
    color: ${GOODWAY_WHITE};
  }
  
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    display: none;
  }
  
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    display: none;
  }
  
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    display: none;
  }
  
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    display: none;
  }
`;

export default StyledDataGrid;
