import { ApolloAuthProvider, AuthDefault, ProtectedApp } from '@goodwaygroup/ui-ux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ApolloWrapper from './components/atoms/ApolloWrapper';
import { GOODLOOK_VIEW_ACCESS } from './components/constants/capabilities';
import DUMMY_USER from './constants/dummyUser';
import Env from './helpers/Env';

const isDev = Env('VITE_NODE_ENV') === 'development';
const preventRedirect = isDev;
const bypassGraphAuth = isDev && Env('VITE_APP_BYPASS_GRAPH_AUTH');

LicenseInfo.setLicenseKey(Env('VITE_APP_MUI_LICENSE'));

AuthDefault({
    bypassEmail: 'sol-dev@goodwaygroup.com',
    bypassGraphAuth,
    promptForMissingAuthToken: isDev,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StrictMode>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ApolloWrapper>
                {bypassGraphAuth ? (
                    <App user={DUMMY_USER} />
                ) : (
                    <ApolloAuthProvider
                        loginUrl={Env('VITE_APP_LOGIN_API_URL')}
                        preventLoginRedirect={preventRedirect}
                        appName='goodlook'
                    >
                        <ProtectedApp
                            capability={GOODLOOK_VIEW_ACCESS}
                            render={user => <App user={user} />}
                        />{' '}
                    </ApolloAuthProvider>
                )}
            </ApolloWrapper>
        </LocalizationProvider>
    </StrictMode>,
);
