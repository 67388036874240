import { useQuery } from '@apollo/client';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GOODWAY_YELLOW } from '../../constants/colors.js';
import { GET_DATASETS_QUERY } from '../../queries/dataset';
import PageLink from '../PageLink';
import StyledDataGrid from '../atoms/StyledDataGrid.jsx';
import DataGridToolbar from '../molecules/DataGridToolbar';
import LinkedButton from '../molecules/LinkedButton.jsx';
import PageTemplate from './PageTemplate.jsx';

const Name = styled.span`
  color: ${GOODWAY_YELLOW};
  font-weight: 200;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  cursor: pointer;

  &:hover {
    color: #fcd119 !important;
    font-weight: 400;
  }
`;

const TableContainer = styled.div`
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
`;

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        renderCell: props => {
            const { row } = props;
            return (
                <PageLink
                    to={{
                        pathname: `/dataset/edit/${row.slug}`,
                        state: {
                            dataset: row.slug,
                        },
                    }}
                    style={{
                        textDecoration: 'none',
                    }}
                >
                    <Tooltip title='Edit Dataset' arrow placement={'left'}>
                        <Name>{row.name}</Name>
                    </Tooltip>
                </PageLink>
            );
        },
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'creator',
        headerName: 'Created By',
        width: 150,
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'createdAt',
        headerName: 'Created Date',
        width: 150,
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        width: 150,
        flex: 1,
        minWidth: 100,
    },
];

const ExistingViewsPage = props => {
    const [reportingAccounts, setDatasets] = useState([]);
    const [selectedPage, setSelectedPage] = useState(0);
    const [selectedPageSize, setSelectedPageSize] = useState(10);
    const { data } = useQuery(GET_DATASETS_QUERY);

    useEffect(() => {
        const datasets =
            data?.getAllDatasets.map(elem => ({
                id: elem.id,
                name: elem.name,
                slug: elem.slug,
                creator: elem.creator,
                creatorID: elem.createrid,
                updatedAt: elem.updatedAt.substring(0, elem.updatedAt.indexOf('T')),
                createdAt: elem.createdAt.substring(0, elem.createdAt.indexOf('T')),
                accounts: elem.accounts,
            })) || [];

        datasets.sort((a, b) => {
            if (a.creator === props.user.fullName && b.creator !== props.user.fullName) {
                return -1; // a comes first because it's created by the current user
            }
            if (a.creator !== props.user.fullName && b.creator === props.user.fullName) {
                return 1; // b comes first because it's created by the current user
            }
            // both objects are created by the same user or neither is created by the current user
            return new Date(b.updatedAt) - new Date(a.updatedAt); // sort by date in descending order (most recent first)
        });
        setDatasets(datasets);
    }, [data]);

    useEffect(() => {
        document.title = 'Goodlook';
    }, []);

    return (
        <PageTemplate pageTitle='Datasets' logoColor='white'>
            <TableContainer>
                {!!reportingAccounts.length && (
                    <StyledDataGrid
                        rows={reportingAccounts}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        components={{
                            Toolbar: () => (
                                <DataGridToolbar columnHide exportCsv>
                                    <LinkedButton link='dataset/create'>Create New</LinkedButton>
                                </DataGridToolbar>
                            ),
                        }}
                        componentsProps={{
                            toolbar: {
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        localeText={{
                            toolbarFilters: '',
                        }}
                        autoHeight={true}
                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={selectedPageSize}
                        page={selectedPage}
                        onPageChange={page => {
                            setSelectedPage(page);
                        }}
                        onPageSizeChange={pageSize => setSelectedPageSize(pageSize)}
                        pagination
                        icon={{ inactiveSortIcon: { opacity: 1 } }}
                    />
                )}
            </TableContainer>
        </PageTemplate>
    );
};

export default ExistingViewsPage;
