import {
    Alert,
    AlertTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Typography,
    styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';
import { PRICING_MENU_ITEMS, PRICING_TYPE_MAP, getGrainHeader } from '../../helpers/pricing';
import StyledButton from '../atoms/StyledButton';
import StyledDatePicker from '../atoms/StyledDatePicker';
import { StyledGridItem } from '../atoms/StyledGridItem';
import StyledModal from '../atoms/StyledModal';
import StyledPricingTextField from '../atoms/StyledPricingTextField';
import MultiSelect from './MultiSelect';
import PricingAutocomplete from './PricingAutocomplete';

const StyledInputLabel = styled(InputLabel)({
    fontSize: '20px',
    color: '#fcd119',
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    fontWeight: 200,
    letterSpacing: '0.05em',
    '&.MuiFocused': {
        color: '#fcd119',
    },

    backgroundColor: GOODWAY_LIGHT,
    paddingLeft: '12px',
});

const INVALID_INPUT_MESSAGE = 'Please fill out all fields correctly.';

const PricingModal = ({ row, grain, dimensions, pricingRules, setPricingRules, open, setOpen }) => {
    const [pricingType, setPricingType] = useState(PRICING_MENU_ITEMS.Fee);
    const [value, setValue] = useState(row?.value || '');
    const [startDate, setStartDate] = useState(row?.start_date);
    const [selected, setSelected] = useState(row?.grains || []);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState('');
    const [hasOverlappingRule, setHasOverlappingRule] = useState(false);
    const scrollContainerRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // handle scrolling for account/campaign selection
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer || !dropdownOpen) {
            return;
        }

        const handleScroll = () => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        };

        // Trigger the scroll handler when selection changes
        handleScroll();
    }, [selected]);

    const handleClose = (_e, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }
        if (isEmpty(row)) {
            resetForm();
        }
        setOpen(false);
    };

    const handlePricingTypeChange = event => {
        setPricingType(PRICING_MENU_ITEMS[event.target.value]);
    };

    const resetForm = () => {
        setValue();
        setStartDate();
        setSelected([]);
        setPricingType(PRICING_MENU_ITEMS.Fee);
        setError('');
        setHasOverlappingRule(false);
    };

    const checkForRuleOverlap = () => {
        if (!startDate || !selected.length) {
            return [];
        }

        const calculation_type = get(PRICING_TYPE_MAP, pricingType, pricingType);
        const selectedGrainIds = selected.map(option => option.platform_grain_id);

        //overlapping rules exist when the selected calculation type, date, and selected items are the same as any rule in pricingRules object
        const overlappingRules =
            pricingRules?.[grain]?.filter(rule => {
                const isSameRow = rule.id === row?.id;

                return (
                    !isSameRow &&
                    (rule.start_date === startDate.format('YYYY-MM-DD') || '') &&
                    get(PRICING_TYPE_MAP, rule.calculation_type, rule.calculation_type) ===
                        calculation_type &&
                    rule.grains.some(({ platform_grain_id }) =>
                        selectedGrainIds.includes(platform_grain_id),
                    )
                );
            }) || [];

        // extract names to display overlapping selections in error message
        const overlappingNames = overlappingRules
            .map(rule =>
                rule.grains.reduce((ruleList, { platform_grain_id, name }) => {
                    if (selectedGrainIds.includes(platform_grain_id)) {
                        ruleList.push(name);
                    }

                    return ruleList;
                }, []),
            )
            .flat();

        return overlappingNames;
    };

    useEffect(() => {
        const ruleConflicts = checkForRuleOverlap();

        if (isEmpty(ruleConflicts)) {
            setHasOverlappingRule(false);
            if (error !== INVALID_INPUT_MESSAGE) {
                setError('');
            }
        } else {
            setHasOverlappingRule(true);
            setError(ruleConflicts.join(','));
        }
    }, [startDate, selected, value, pricingType]);

    const handleConfirm = () => {
        const ruleConfig = pricingType?.validate({
            startDate,
            value,
            pricingType,
            grain,
            selected,
        });

        if (!ruleConfig) {
            setError(INVALID_INPUT_MESSAGE);
            return;
        }
        if (hasOverlappingRule) {
            return;
        }
        setValue(oldValue => parseFloat(oldValue).toString());
        setError('');
        setOpen(false);

        if (Object.keys(ruleConfig).length > 0) {
            setPricingRules(oldPricingRules => {
                const newPricingRules = { ...oldPricingRules };
                if (ruleConfig && isEmpty(newPricingRules?.[grain] || [])) {
                    newPricingRules[grain] = [];
                }
                const matchingRuleIndex = newPricingRules[grain]?.findIndex(
                    rule => rule.id === row?.id,
                );

                if (matchingRuleIndex >= 0) {
                    newPricingRules[grain][matchingRuleIndex] = ruleConfig;
                } else {
                    newPricingRules[grain].push(ruleConfig);
                }
                return newPricingRules;
            });
        }

        resetForm();
        setOpen(false);
    };

    useEffect(
        () =>
            setOptions(
                dimensions?.[grain]?.map(
                    ({ platform_grain_id, id, name, platform, alias }, index) => ({
                        id,
                        platform_grain_id,
                        alias,
                        name,
                        platform,
                        index,
                    }),
                ) || [],
            ),
        [dimensions, row],
    );

    useEffect(() => {
        if (isEmpty(row)) {
            resetForm();
        } else {
            setPricingType(row?.calculation_type || {});
            setValue(row?.value || '');
            setStartDate(dayjs(row?.start_date) || '');
            setSelected(row.grains || []);
        }
    }, [row, grain, row.grains]);

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            header={`${isEmpty(row) ? 'Add New' : 'Edit'} Pricing Adjustment`}
        >
            {error && (
                <Grid
                    container
                    sx={{
                        paddingTop: '0 !important',
                        paddingLeft: '0 !important',
                        paddingBottom: '20px',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Alert severity='warning' sx={{ width: '75%' }}>
                        <AlertTitle>
                            {hasOverlappingRule
                                ? `Warning - overlapping ${
                                      PRICING_MENU_ITEMS[pricingType.id].name
                                  } adjustments exist for the following ${grain}s:`
                                : error}
                        </AlertTitle>
                        {hasOverlappingRule &&
                            error
                                .split(',')
                                .map(overlappingItem => (
                                    <Typography key={`${overlappingItem}`}>
                                        {overlappingItem}
                                    </Typography>
                                ))}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12} sx={{ width: '100%', paddingTop: '0 !important', marginTop: '0' }}>
                <Grid
                    container
                    spacing={3}
                    direction='column'
                    sx={{
                        flexDirection: 'column',
                        alignContent: 'space-evenly',
                        align: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        paddingTop: '0 !important',
                    }}
                >
                    <StyledGridItem item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <StyledInputLabel
                                shrink={true}
                                id='grain-input-label'
                                sx={{ paddingLeft: '5px' }}
                            >
                                {getGrainHeader(grain)}*
                            </StyledInputLabel>
                            <div
                                ref={scrollContainerRef}
                                style={{ overflowY: 'auto', maxHeight: '50%' }}
                            >
                                <PricingAutocomplete
                                    grain={grain}
                                    options={options}
                                    selected={selected}
                                    setSelected={setSelected}
                                    scrollContainerRef={scrollContainerRef}
                                    setDropdownOpen={setDropdownOpen}
                                />
                            </div>
                        </FormControl>
                    </StyledGridItem>
                    <StyledGridItem item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <StyledInputLabel
                                shrink={true}
                                id='adjustment-input-label'
                                sx={{ padding: 0 }}
                            >
                                Adjustment Type*
                            </StyledInputLabel>
                            <MultiSelect
                                value={pricingType.id || ''}
                                defaultValue={'Fee'}
                                onChange={handlePricingTypeChange}
                                menuItemConfig={Object.values(PRICING_MENU_ITEMS).filter(
                                    ({ filter }) =>
                                        !filter ||
                                        filter({
                                            row,
                                            grain,
                                            pricingRules,
                                        }),
                                )}
                            />
                        </FormControl>
                    </StyledGridItem>
                    {!pricingType.isImmutable && (
                        <>
                            <StyledGridItem item xs={12} sx={{ paddingTop: '10px !important' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <StyledInputLabel
                                        shrink={true}
                                        id='value-input-label'
                                        sx={{ paddingRight: '0', paddingLeft: '0' }}
                                    >
                                        {pricingType.text}*
                                    </StyledInputLabel>
                                    <StyledPricingTextField
                                        value={value || ''}
                                        name={'value'}
                                        type='number'
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                            min: pricingType.min,
                                            max: pricingType.max,
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                pricingType.symbol === '%' ? (
                                                    <InputAdornment
                                                        position='start'
                                                        sx={{
                                                            color: GOODWAY_YELLOW,
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        <StyledInputLabel
                                                            id='inputLabel'
                                                            sx={{ fontSize: 10 }}
                                                            position='start'
                                                        >
                                                            {pricingType.symbol}
                                                        </StyledInputLabel>
                                                    </InputAdornment>
                                                ) : null,
                                            startAdornment:
                                                pricingType.symbol === '$' ? (
                                                    <InputAdornment
                                                        position='start'
                                                        sx={{ color: GOODWAY_YELLOW, fontSize: 10 }}
                                                    >
                                                        <StyledInputLabel
                                                            id='inputLabel'
                                                            sx={{ fontSize: 10, paddingLeft: 0 }}
                                                            position='start'
                                                        >
                                                            {pricingType.symbol}
                                                        </StyledInputLabel>
                                                    </InputAdornment>
                                                ) : null,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { backgroundColor: GOODWAY_LIGHT },
                                        }}
                                        onChange={({ target }) => setValue(target.value)}
                                        sx={{
                                            width: '100%',
                                        }}
                                        required
                                    />
                                </FormControl>
                            </StyledGridItem>
                            <StyledGridItem item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <StyledInputLabel
                                        shrink={true}
                                        id='date-input-label'
                                        sx={{ paddingLeft: '5px' }}
                                    >
                                        Effective Date*
                                    </StyledInputLabel>
                                    <StyledDatePicker
                                        value={startDate}
                                        onChange={setStartDate}
                                        sx={{ display: 'flex', width: '100%' }}
                                        emptyLabel='custom label'
                                    />
                                </FormControl>
                            </StyledGridItem>
                        </>
                    )}
                    <StyledGridItem item xs={12}>
                        <StyledButton
                            variant='contained'
                            onClick={handleConfirm}
                            autoFocus
                            sx={{
                                marginLeft: 0,
                                align: 'left',
                                marginTop: '10px',
                                borderRadius: '5px',
                            }}
                        >
                            Apply
                        </StyledButton>
                    </StyledGridItem>
                </Grid>
            </Grid>
        </StyledModal>
    );
};

export default PricingModal;
