import { Tooltip } from '@mui/material';
import { useContext } from 'react';

import WarnUserContext from '../../helpers/WarnUserContext';
import StyledButton from '../atoms/StyledButton';

const LockButton = ({ accountsLocked, setConfirmLockDataset, disabled }) => {
    const { warnUser } = useContext(WarnUserContext);
    const canLock = accountsLocked === false && warnUser === false;

    return canLock ? (
        <StyledButton
            marginleft='10px'
            variant='outlined'
            onClick={() => setConfirmLockDataset(true)}
            highlightcolor='#ffa500'
            disabled={disabled}
        >
            Lock
        </StyledButton>
    ) : (
        <Tooltip
            title={
                accountsLocked
                    ? 'Dataset is locked - please contact IT to unlock.'
                    : 'Cannot lock dataset with unsaved changes.'
            }
            placement='bottom'
        >
            <span>
                <StyledButton
                    marginleft='10px'
                    variant='outlined'
                    disabled
                    highlightcolor='#ffa500'
                >
                    {accountsLocked ? 'Unlock' : 'Lock'}
                </StyledButton>
            </span>
        </Tooltip>
    );
};

export default LockButton;
