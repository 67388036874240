import { GOODWAY_WHITE } from '../../constants/colors';
import StyledButton from './StyledButton';

export const CancelButton = ({ onCancel }) => (
    <StyledButton
        onClick={onCancel}
        highlightcolor={GOODWAY_WHITE}
        variant='outlined'
        marginleft='10px'
    >
        Cancel
    </StyledButton>
);
