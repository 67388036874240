import { Stack } from '@mui/material';
import { memo } from 'react';
import { BulkEditSubheaderSection } from '../atoms/BulkEditSubheaderSection';

const BulkEditSubheader = ({ selectedRows = [], rows = [], getRowSubheader = () => {} }) => {
    const otherRows = Math.max(selectedRows.length - 3, 0);
    const displayRows = selectedRows.slice(0, 3).map(rowId => {
        const row = rows.find(({ id }) => id === rowId);
        const subheader = getRowSubheader(row);

        return <BulkEditSubheaderSection key={rowId} subheader={subheader} />;
    });

    if (otherRows > 0) {
        displayRows.push(
            <BulkEditSubheaderSection
                subheader={`and ${otherRows} others`}
                style={{ fontStyle: 'italic', marginTop: 1 }}
            />,
        );
    }

    return <Stack style={{ marginTop: 8 }}>{displayRows}</Stack>;
};

export default memo(BulkEditSubheader);
