import { CircularProgress, Typography } from '@mui/material';

const PartialOverlayModal = ({ isLoading, children }) => {
    return (
        <div style={{ position: 'relative' }}>
            {children}
            {isLoading && (
                <div
                    style={{
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.40)',
                        bottom: 0,
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        left: 0,
                        padding: 4,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 1000,
                        minHeight: '100px',
                    }}
                >
                    <CircularProgress />
                    <Typography variant='h6' sx={{ marginTop: 2 }}>
                        Loading Data
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default PartialOverlayModal;
