import { gql } from '@apollo/client';

const GET_DATASET_TEMPLATE = `
    {
      id
      name
      slug
      updatedAt
      assetBucket
      sigmaFolderName
      excludeFinancialColumns
      accountsLocked
      version {
        version
        latest
        changes
      }
      destinations {
        id
        destinationMap
        destination
        result
        resultType
        datasetVersions
        artifact
        createdAt
        updatedAt
        slug
        displayName
        description
        status
        externalUrl
      }
      accounts {
        id
        platform
        grain {
          id
          name
          platform
          platform_grain_id
          type
        }
      }
      datasetLinkedAssets {
        config
        createdAt
        datasetID
        id
        key
        updatedAt
        url
        type
      }
      datasetAssets {
        config
        createdAt
        datasetID
        id
        key
        updatedAt
        url
      }
      datasetRules {
        id
        datasetID
        type
        grain {
          id
          platform_grain_id
          name
          type
          platform
          path
        }
        config {
          ... on alias {
            type
            field
            default_field
            key
            alias
          }
          ... on tag {
            tag_type
            field
            key
            bucket
            grain
            grain_id
            type
            tag_value
            reference
          }
          ... on calculation {
            calculation_type
            value
            start_date
            grain
            grain_ids
          }
          ... on grainFilter {
            field
            key
          }
          ... on zeroColumn {
            name
          }
        }
        createdAt
        updatedAt
      }
    }
`;
export const CHECK_DESTINATION_STATUS = gql`
  query DestinationStatus($datasetId: ID) {
    destinationStatus(datasetID: $datasetId) {
      destination
      status
    }
  }
`;
export const SAVE_DATASET = gql`
  mutation CreateDataset(
    $name: String!
    $creator: String!
    $creatorId: Int!
    $assetBucket: String!
    $excludeFinancialColumns: Boolean!
    $accountsLocked: Boolean
    $accountMap: [AccountMapInput!]!
    $datasetId: Int
    $destinations: [DestinationsInput]
    $datasetRules: [DatasetRuleInput!]
    $datasetAssets: [assetInput]
    $updateVersion: Boolean!
    $sigmaFolderName: String
  ) {
    createDataset(
      name: $name
      creator: $creator
      creatorID: $creatorId
      assetBucket: $assetBucket
      excludeFinancialColumns: $excludeFinancialColumns
      accountsLocked: $accountsLocked
      accountMap: $accountMap
      datasetId: $datasetId
      destinations: $destinations
      datasetRules: $datasetRules
      datasetAssets: $datasetAssets
      updateVersion: $updateVersion
      sigmaFolderName: $sigmaFolderName 
    ) {
      code
      message
      success
      dataset ${GET_DATASET_TEMPLATE}
    }
  }
`;

export const GET_DATASET = gql`
  query datasetBySlug($slug: String!) {
    getDatasetBySlug(slug: $slug) ${GET_DATASET_TEMPLATE} 
  }
`;

export const GET_DATASETS_NAMES_QUERY = gql`
  query GetAllDatasets {
    getAllDatasets {
      id
      slug
      name
    }
  }
`;

export const DELETE_DATASETS_MUTATION = gql`
  mutation DeleteDatasetById($id: ID!) {
    deleteDatasetById(id: $id) {
      code
      message
      success
      dataset {
        id
        name
        slug
      }
    }
  }
`;

export const LOCK_DATASET = gql`
  mutation LockDataset($id: ID!) {
    lockDatasetById(id: $id) {
      code
      message
      success
      dataset ${GET_DATASET_TEMPLATE}
    }
  }
`;

export const GET_DATASETS_QUERY = gql`
  query GetAllDatasets {
    getAllDatasets {
      id
      slug
      name
      updatedAt
      createdAt
      creator
      creatorID
      assetBucket
      excludeFinancialColumns
    }
  }
`;
