import { Box, Tooltip } from '@mui/material';
import { forwardRef } from 'react';

export const PlatformLogo = forwardRef(({ platform, placement = 'top' }, _logoRef) => {
    const PlatformTooltip = forwardRef((props, tooltipRef) => (
        <Tooltip ref={tooltipRef} {...props} />
    ));

    return (
        <PlatformTooltip title={platform?.name} placement={placement} arrow={true}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <img src={platform?.logo} style={{ width: 20, height: 20 }} />
            </Box>
        </PlatformTooltip>
    );
});
