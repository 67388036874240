import { Chip, styled } from '@mui/material';

const StyledChip = styled(Chip)(() => ({
    color: '#1a1d30',
    backgroundColor: '#fff',
    textTransform: 'uppercase',
    marginRight: 10,
    marginLeft: 0,
    '&:hover': {
        backgroundColor: '#fcd119',
    },
    '& path': {
        color: '#1a1d30 !important',
    },
}));

export default StyledChip;
