import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useInterval } from 'react-use';
import DatasetForm from './components/pages/DatasetForm';
import ExistingViewsPage from './components/pages/ExistingViews';
import LoadPendo from './components/pendo/LoadPendo';
import LoadFullStory from './fullstory/LoadFullStory';
import Env from './helpers/Env';
import WarnUserProvider from './helpers/WarnUserProvider';

const App = ({ user }) => {
    const isDev = Env('VITE_NODE_ENV') === 'development';
    const GET_USER_DATA = gql`
    query getUserData {
      userData @rest(type: "UserData", path: "/v1/users/me", method: "GET") {
        email
        fullName
        id
        isOkta
        landingPage
        scope
      }
    }
  `;

    const [checkAuth, { error, data }] = useLazyQuery(GET_USER_DATA, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (error) {
            const fromGL = encodeURIComponent(`/goodlook`);
            let loginApiUrl = Env('VITE_APP_LOGIN_API_URL');
            loginApiUrl = loginApiUrl.replace('{from}', fromGL);
            window.location.href = loginApiUrl;
        }
    }, [error, data]);

    useInterval(() => {
        if (!isDev) {
            checkAuth();
        }
    }, 30000);

    return (
        <WarnUserProvider>
            <BrowserRouter>
                <LoadFullStory
                    orgId={Env('VITE_APP_FULLSTORY_ORG_ID')}
                    user={user}
                    secret={Env('VITE_APP_FULLSTORY_SECRET')}
                />
                <LoadPendo user={user} />
                <Routes>
                    <Route path='/' exact element={<ExistingViewsPage user={user} />} />
                    <Route path='/dataset/create' exact element={<DatasetForm user={user} />} />
                    <Route path='/dataset/edit/:slug' exact element={<DatasetForm user={user} />} />
                </Routes>
            </BrowserRouter>
        </WarnUserProvider>
    );
};

export default App;
