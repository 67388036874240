import { gql } from '@apollo/client';

export const UPLOAD_ASSET = gql`
  mutation ($base64: String!, $bucket: String!, $fileName: String!) {
    uploadAsset(base64: $base64, bucket: $bucket, fileName: $fileName) {
      code
      message
      key
      url
      success
    }
  }
`;
