import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import sigmaLogo from '../../assets/destinationLogos/sigmaLogo.svg';
import { GOODWAY_WHITE } from '../../constants/colors';
import { StyledModalHeader } from '../atoms/StyledModalHeader';

const SigmaDestinationConfig = ({
    setDestinations,
    destinations,
    destination,
    close,
    destinationMaps,
}) => {
    const getDestinationOptions = (inputDestinations = destinations) => {
        const existingSigmaDestinationTemplates = inputDestinations.reduce(
            (sigmaTemplates, { destination, artifact }) => {
                if (destination === 'sigma' && artifact) {
                    sigmaTemplates.push(artifact);
                }
                return sigmaTemplates;
            },
            [],
        );

        return destinationMaps.filter(
            ({ destination, artifact }) =>
                destination === 'sigma' && !existingSigmaDestinationTemplates.includes(artifact),
        );
    };

    const [destinationOptions, setDestinationOptions] = useState(getDestinationOptions());

    useEffect(() => {
        setDestinationOptions(getDestinationOptions());
    }, [destinations]);

    const handleSelect = option => {
        const {
            artifact,
            description,
            displayName,
            id: destinationMap,
            slug,
            versionMax,
            versionMin,
            externalTemplate,
        } = option;

        setDestinations(oldDestinations => {
            const newDestinations = oldDestinations.map(oldDestination => {
                if (oldDestination.id === destination.id) {
                    return {
                        ...oldDestination,
                        artifact,
                        description,
                        displayName,
                        destinationMap,
                        slug,
                        versionMax,
                        versionMin,
                        externalTemplate,
                        ...oldDestination.initialize(option),
                    };
                }

                return oldDestination;
            });

            setDestinationOptions(getDestinationOptions(newDestinations));

            return newDestinations;
        });

        close();
    };

    return (
        <>
            <StyledModalHeader header={'Choose Sigma Template:'} />
            <Grid container spacing={2}>
                {destinationOptions?.length ? (
                    destinationOptions.map(option => (
                        <Grid key={option.id} item xs={12} md={6} lg={4}>
                            <Card
                                sx={{
                                    height: '100%',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    },
                                }}
                                key={option.artifact}
                                onClick={() => handleSelect(option)}
                            >
                                <CardMedia
                                    component={'img'}
                                    image={sigmaLogo}
                                    title={'Sigma Logo'}
                                    sx={{
                                        height: 140,
                                        objectFit: 'scale-down',
                                        padding: 1,
                                    }}
                                />
                                <CardActionArea>
                                    <CardContent>
                                        <Typography variant='h6'>{option.name}</Typography>
                                        <Typography variant='body2'>
                                            {option.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <>
                        <Typography variant='h6' ml={6} mt={2} sx={{ color: GOODWAY_WHITE }}>
                            No Sigma Templates Available for this Dataset version
                        </Typography>
                        <Typography variant='body1' ml={6} mb={8} sx={{ color: GOODWAY_WHITE }}>
                            Please save your dataset to update the version, then try again.
                        </Typography>
                    </>
                )}
            </Grid>
        </>
    );
};

export default SigmaDestinationConfig;
