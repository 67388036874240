import DataGridToolbar from './DataGridToolbar';
import GrainSelector from './GrainSelector';

const PreviewDataGridToolbar = ({ grainOptions, currentDimension, setCurrentDimension }) => (
    <DataGridToolbar exportCsv columnHide>
        <GrainSelector
            grainOptions={grainOptions}
            currentDimension={currentDimension}
            setCurrentDimension={setCurrentDimension}
        />
    </DataGridToolbar>
);

export default PreviewDataGridToolbar;
