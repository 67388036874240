import { DesktopDatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

const StyledDatePicker = styled(DesktopDatePicker)({
    '& input': {
        color: 'white',
    },
    '& path': {
        color: '#fcd119',
    },
    '& fieldset': {
        borderColor: 'white',
        borderRadius: '15px',
    },
    '&&:hover .MuiOutlinedInput-notchedOutline, &MuiInputBase-colorPrimary:after': {
        borderColor: '#fcd119',
        color: '#fcd119',
    },
});

export default StyledDatePicker;
